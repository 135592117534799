import { determineEnvironment } from '@cultureamp/frontend-env/lib';
function shouldLog() {
  return determineEnvironment().realm !== "test" || process.env.DEBUG !== undefined;
}
function getLevel(response) {
  // for log levels we consider as errors something that either didn't reach service
  // or was client (caller) error, except not found which is commonly used as standard response instead of error
  // error level: no status, 4XX-404
  // warn level: 500+
  // info level: 404
  if ((response === null || response === void 0 ? void 0 : response.status) === 404) return "info";
  if ((response === null || response === void 0 ? void 0 : response.status) && response.status >= 500) return "warn";
  return "error";
}
async function getMessage(response) {
  var _a, _b;
  const body = await (response === null || response === void 0 ? void 0 : response.clone().text()); // need to clone the response to avoid error "body used already for:..."
  // Fetch error [500 Server Error]: (body length: 40 chars)
  return `Fetch error for ${response === null || response === void 0 ? void 0 : response.url} [${(_a = response === null || response === void 0 ? void 0 : response.status) !== null && _a !== void 0 ? _a : "FAILURE"} ${response === null || response === void 0 ? void 0 : response.statusText}]  (body length: ${(_b = body === null || body === void 0 ? void 0 : body.length) !== null && _b !== void 0 ? _b : 0} chars)`;
}
// todo we should not console log in tests by default but only when process.env.DEBUG is set
// log middleware that is currently using console[info|warn|error] as target as Sentry and other tools hook to that
// in basic version it logs only safe parts of object (eg response body, or error message) and avoids dumping whole objects as it can lead to memory leaks.
// when we have object serializer that protects us from those leaks we can log more information
const log = async (ctx, next) => {
  var _a;
  try {
    await next();
  } catch (e) {
    if (shouldLog()) {
      /* eslint-disable-next-line no-console */
      console.error(`Fetch exception: ${e.message}`);
    }
    throw e;
  } finally {
    // log failed responses
    if (((_a = ctx.response) === null || _a === void 0 ? void 0 : _a.ok) === false) {
      const level = getLevel(ctx.response);
      const message = await getMessage(ctx.response);
      if (shouldLog()) {
        /* eslint-disable-next-line no-console */
        console[level](message);
      }
    }
  }
};
export { log };
