import { dispatchForbiddenEvent } from '../fetch/middlewares/dispatchForbiddenEvent.mjs';
import { fetchFactory } from '../fetch/fetchFactory.mjs';
import { fetchWithPreload } from '../fetch/middlewares/fetchWithPreload.mjs';
import { localDev } from '../fetch/middlewares/localDev.mjs';
import { localEndpointPrefix } from '../fetch/middlewares/localEndpointPrefix.mjs';
import { log } from '../fetch/middlewares/log.mjs';
import { queryString } from '../fetch/middlewares/queryString.mjs';
import { redirectUnauthorised } from '../fetch/middlewares/redirectUnauthorised.mjs';
import { requestBodyMiddleware } from '../fetch/middlewares/requestBodyMiddleware.mjs';
import { responseBody, responseError } from '../hooks/responseHandler.mjs';
import { testEnv } from '../fetch/middlewares/testEnv.mjs';
import { timeout } from '../fetch/middlewares/timeout.mjs';
import { upcaseMethod } from '../fetch/middlewares/upcaseMethod.mjs';
import { urlParamsMiddleware } from '../fetch/urlParamsMiddleware.mjs';
const QUERY_FETCH_MIDDLEWARES = [upcaseMethod, localEndpointPrefix, urlParamsMiddleware, queryString, requestBodyMiddleware, localDev, testEnv, log, timeout, redirectUnauthorised, dispatchForbiddenEvent, fetchWithPreload];
const queryFetchWithMiddlewares = fetchFactory(QUERY_FETCH_MIDDLEWARES);
const queryFetch = (url, options) => {
  if (typeof window === "undefined") {
    const fakePromise = new Promise(resolve => {
      resolve();
    });
    //@ts-expect-error As we only need preload for server-side, we want to return args provided to queryFetch to use them for preload
    // This fake promise on server side will prevent actual request being made on server-side and won't affect the client-side
    fakePromise.args = {
      url,
      options
    };
    return fakePromise;
  }
  return queryFetchWithMiddlewares(url, options).then(response => {
    if (response.ok) {
      return responseBody(response);
    }
    return responseError(response, url);
  });
};
export { queryFetch };
